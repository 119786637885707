var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('fragment',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","block":"","disabled":_vm.items.length === 0},on:{"click":function($event){return _vm.dowloadExportCsv()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-tray-arrow-down ")]),_vm._v(" "+_vm._s(((_vm.$t('btn.export')) + " CSV"))+" ")],1)],1),_c('v-spacer'),(_vm.tabla)?_c('v-col',{staticClass:"text-lowercase",attrs:{"cols":"12","md":"2"}},[_c('span',{staticClass:"pt-5"},[_vm._v(" "+_vm._s(_vm.totalF)+" "+_vm._s(_vm.$t('lbl.de'))+" "+_vm._s(_vm.totalItems)+" "),_c('span',{staticClass:"text-lower"},[_vm._v(_vm._s(_vm.tabla.name))])])]):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"value":_vm.itemsPerPage,"label":_vm.$t('lbl.itemsPerPage'),"type":"number","min":"1","max":"15","dense":"","outlined":"","disabled":""},on:{"input":function($event){_vm.itemsPerPage = parseInt($event, 10)}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","dense":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lbl.notShowElement'))+" ")]},proxy:true},{key:"item.Contactos",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"45%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Contactos))])])]}},{key:"item.Características",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"45%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Características))])])]}},{key:"item.Intereses",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"45%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Intereses))])])]}},{key:"item.Servicios",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"45%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Servicios))])])]}},{key:"item.Descripción",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"45%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.Descripción)}})])]}},{key:"item.Habitaciones",fn:function(ref){
var item = ref.item;
return [(item.Habitaciones)?_c('v-tooltip',{attrs:{"top":"","max-width":"45%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Habitaciones))])]):_vm._e()]}}],null,false,1891676978)}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"12","cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }